


import { Component, Vue, Watch } from 'vue-property-decorator';
import newsThumbnail from '@/_modules/news/components/news-thumbnail/news-thumbnail.vue';
import iconNews from '@/_modules/icons/components/sidebar/icon-news.vue';
import { TNewsIssue } from '@/_types/news-issue.type';
import newsApi from '@/_api/news.api';

@Component({
  components: {
    newsThumbnail,
    iconNews
  }
})
export default class NewsList extends Vue {

  public newsList: TNewsIssue[] = [];
  public isListLoading: boolean = false;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get newsIssueId(): number {
    return this.$route.params.newsIssueId ? parseInt(this.$route.params.newsIssueId, 10) : null;
  }

  @Watch('newsIssueId')
  public onNewsIssueIdChange(): void {
    this.scrollToCurrentNewsIssue();
  }

  @Watch('newsList')
  public onNewsListChange(): void {
    this.scrollToCurrentNewsIssue();
  }

  private scrollToCurrentNewsIssue(): void {
    if (!this.newsIssueId) {
      return;
    }

    this.$nextTick(() => {
      const GRID_GAP = 18;
      const openNewsIssueIndex: number = this.newsListFiltered.length === 0 ? 0 : this.newsListFiltered.findIndex((newsIssue) => newsIssue.id === this.newsIssueId);

      if(this.$refs.newsListItems) {
        const openNewsListItem: Element = (this.$refs.newsListItems as Element[])[openNewsIssueIndex];
        if (!openNewsListItem) {
          return;
        }
        const rectItem: DOMRect = openNewsListItem.getBoundingClientRect();
        this.$emit('scrollTopUpdated', (rectItem.height + GRID_GAP) * openNewsIssueIndex);
      }
    });
  }

  public get isNewsListEmpty(): boolean {
    if (!this.newsListFiltered) {
      return true;
    }
    return this.newsListFiltered.length === 0;
  }

  public get newsListFiltered(): TNewsIssue[] {
    return this.newsList.filter((newsIssue) => newsIssue.is_published);
  }

  public mounted(): void {
    this.getNewsList();
  }

  private async getNewsList(): Promise<void> {
    this.isListLoading = true;
    this.newsList = await newsApi.getEventNews({ eventId: this.eventId, onlyPublished: true, offset: 0, limit: 100 });
    this.isListLoading = false;
  }

}
